<template>
  <FastOrdersTableAdmin
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      @changeTab="changeTab"
      @resetFilter="resetFilter"
      @changeFilter="changeFilter"
      @showMore="showMore"
      @reload="reload"
      @deleteItem="deleteItem"
  />
</template>

<script>
  import FastOrdersTableAdmin from "./FastOrdersTableAdmin/FastOrdersTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {fastOrdersSocketMixin} from "../../../../../mixins/fastOrdersMixins/fastOrdersMixin";
  import {FAST_ORDER_STATUS_SUCCESS} from "../../../../../staticData/staticVariables";

  export default {
    name: "FastOrdersTable",

    components: {
      FastOrdersTableAdmin,
    },

    mixins: [routeFilter, fastOrdersSocketMixin],

    computed: {
      router: function() {
        return this.$route.query
      }
    },

    watch: {
      loadUserAuthorizedData: function () {
        // set tab active and visible by permissions
        if(this.downloadPermissions){
          this.checkTabPermission()
        }
      },

      downloadPermissions() {
        this.checkTabPermission()
      }
    },

    data() {
      return {
        forPage: this.$store.getters.getFastOrdersForPage,
        page: 1,
        onePage: 1,

        filterId: '',
        filterStatus: '',
        filterDate: [],

        filterUserId: '',
        filterUserName: '',

        countFilterParams: 0,
        filterGetParams: {},

      }
    },

    mounted() {
      if(this.loadUserAuthorizedData){
        // set tab active and visible by permissions
        if(this.downloadPermissions){
          this.checkTabPermission()
        }
      }
    },

    beforeDestroy() {
      if(this.$store.getters.getFastOrdersSocket){
        this.destroySocketChannel('FastOrders', 'setFastOrdersSocket')
      }
      if(this.$store.getters.getFastOrdersInitSocket){
        this.$store.commit('successFastOrdersInitSocket')
      }
    },

    methods: {
      checkTabPermission() {

        // this.activeTabByPermissions('navTabs', {
        //   [DHL_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_DHL,
        //   [FEDEX_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_FEDEX,
        //   [TNT_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_TNT,
        //   [DPD_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_FEDEX,
        // })
        //
        // this.hideTabAllIfVisibleOnlyOne('navTabs', 'all', ['permissionVisible'])
        //
        // if(!this._.find(this.navTabs, {active: true}).permissionVisible){
        //   this.setActivePermissionVisibleTab('navTabs')
        //   return
        // }

        this.filter()

        console.log('check init socket', this.$store.getters.getFastOrdersInitSocket);
        if (this.$store.getters.getFastOrdersInitSocket) {
          /**
           * Create Socket Channel
           * @type {*|{channel, active, event, user}|{active}}
           */
          let prepareSocket = this.prepareCreatePrivateSocketChannel('FastOrders')
          if (prepareSocket.active) {

            console.log('check prepare socket channel');

            this.createFastOrdersSocket(
                prepareSocket,
                (response) => {

                  console.log('test socket response', response, response?.data);
                  if (response.data?.status === 'success') {
                    let items = this.$store.getters.getFastOrders

                    let changeFlag = false
                    items = items.map(item => {
                      console.log(item?.id, item, this.$store.getters.getFastOrdersSocketItemId);
                      if (+item.id === +this.$store.getters.getFastOrdersSocketItemId) {
                        item.status = FAST_ORDER_STATUS_SUCCESS.value
                        item.progress_percentage = response.data?.percent
                        changeFlag = true
                        this.openNotify('success', 'common_notificationRecordChanged')
                      }

                      return item
                    })

                    if (changeFlag) {
                      this.$store.commit('setFastOrders',{fastOrders: items})
                    }
                    this.$store.commit('setFastOrdersSocketItemId', '')
                  }

                  if (response.data?.status === 'in_process') {
                    let items = this.$store.getters.getFastOrders

                    let changeFlag = false
                    items = items.map(item => {
                      if (+item.id === +this.$store.getters.getFastOrdersSocketItemId) {
                        item.progress_percentage = response.data?.percent
                        changeFlag = true
                      }

                      return item
                    })

                    if (changeFlag) {
                      this.$store.commit('setFastOrders',{fastOrders: items})
                    }
                  }
                }
            )

            this.$store.commit('successFastOrdersInitSocket')
          }
        }
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,

          id: this.$route.query.id,
          status: this.$route.query.status,
          date: this.$route.query.date,

          userId: this.$route.query.userId,
          userName: this.$route.query.userName,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        this.$store.commit('setFastOrdersFilter', window.location.search)

        if (next) this.$store.commit('setNextFastOrdersPage', true)
        this.$store.dispatch('fetchFastOrders', url).then(() => {
          this.$store.commit('setNextFastOrdersPage', false)
        })


        this.checkCountFilter(['userName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
            myQuery,
            {
              filterId: 'id',
              filterStatus: 'status',
            },
        )

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
              'byCreatedAt',
              date
          )
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      deleteItem(id) {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_yes',
          no: 'common_no'
        }

        let confirm = () => {

          this.$store.dispatch('deleteFastOrders', id).then((response) => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.openNotify('success', 'common_notificationRecordChanged')
                this.$emit('close')
                this.filter()
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

    }
  }
</script>

<style scoped>

</style>