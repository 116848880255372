<template>
    <div class="table-card__item-content"
         :class="{show: show}"
    >
      <div class="table-card__item-head">
        <div class="table-card__item-head-row d-flex">
          <div class="table-card__item-number table-card__item-number--small"
          >
            #{{item.id}}
          </div>
        </div>
      </div>
      <transition name="slide">
        <div class="table-card__item-body"
             v-if="show"
        >
<!--          <div class="table-card__item-row custom-row">-->
<!--            <div class="table-card__item-col custom-col">-->
<!--              <div class="table-card__item-label">-->
<!--                {{$t('common_user.localization_value.value')}}-->
<!--              </div>-->
<!--              <div class="table-card__item-info">-->
<!--                <LinkButton-->
<!--                    :value="item.user.email"-->
<!--                    :link="$store.getters.GET_PATHS.mainSettingsUsers + `?userId=${item.user_id}`"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

          <div class="table-card__item-row custom-row">
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('fastOrders_status.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <template v-if="item.status === FAST_ORDER_STATUSES.FAST_ORDER_STATUS_NEW.value">
                  {{$t('fastOrders_newStatus.localization_value.value')}}
                </template>
                <template v-if="item.status === FAST_ORDER_STATUSES.FAST_ORDER_STATUS_IN_PROGRESS.value">
                  {{$t('fastOrders_inProgressStatus.localization_value.value')}}
                </template>
                <template v-if="item.status === FAST_ORDER_STATUSES.FAST_ORDER_STATUS_SUCCESS.value">
                  {{$t('fastOrders_successStatus.localization_value.value')}}
                </template>
                <template v-if="!item.progress_percentage && $store.getters.getFastOrdersSocketItemId === item.id">
                  <span class="color-green-important">(0%)</span>
                </template>
                <template v-if="+item.progress_percentage >= 0 && +item.progress_percentage !== 100">
                  <span class="color-green-important">({{item.progress_percentage}}%)</span>
                </template>
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('fastOrders_created.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                {{ item.created_at | moment("DD MMM, YYYY HH:mm") }}
              </div>
            </div>

          </div>

          <div class="table-card__item-btn pl-2">
            <div class="table-card__item-btn-i">
              <LinkButton
                  :value="$t('common_edit.localization_value.value')"
                  :type="'edit'"
                  :link="$store.getters.GET_PATHS.reportsFastOrdersEdit + '/' + item.id"
              />
            </div>
            <div class="table-card__item-btn-i">
              <LinkButton
                  :value="$t('common_delete.localization_value.value')"
                  :type="'delete'"
                  @click.native="$emit('deleteItem', item.id)"
              />
            </div>
            <div v-if="item.status === FAST_ORDER_STATUS_SUCCESS.value" class="table-card__item-btn-i">
              <LinkButton
                  :value="$t('fastOrders_getLabels.localization_value.value')"
                  :type="'dog'"
                  :target="'_blank'"
                  :link="$store.getters.GET_PATHS.generatePdfFile + '/get-labels-fast-order?id=' + item.id"
              />
            </div>

          </div>
        </div>
      </transition>
      <div class="table-card__item-footer">
        <div class="table-card__item-show"
             @click="show = !show"
        >
          {{$t('common_showMore.localization_value.value')}}
        </div>
      </div>
    </div>
</template>

<script>
  // import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import {FAST_ORDER_STATUSES, FAST_ORDER_STATUS_SUCCESS} from "../../../../../../../../staticData/staticVariables";

  export default {
    name: "FastOrdersTableMobile",

    components: {
      LinkButton,
    },

    props: {
      item: Object,
      selectedNow: Boolean,
    },

    data() {
      return {
        show: false,
        hideEditField: false,
        FAST_ORDER_STATUS_SUCCESS: FAST_ORDER_STATUS_SUCCESS,
        FAST_ORDER_STATUSES: FAST_ORDER_STATUSES,
      }
    },

    methods: {

    },

  }
</script>

<style scoped>
  .conso-user-select {
    min-width: 150px;
  }

  .table-card .ellipsis {
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: inline-block;
  }
</style>
