<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fastOrders_id'])"></span>

        <DefaultInput
            :label="$t('fastOrders_id.localization_value.value')"
            :type="'text'"
            v-model="id"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
<!--        <span class="admin-edit" @click="editTranslate(['order_express'])"></span>-->

        <DefaultInput
            :label="$t('searchEngine_express.localization_value.value')"
            :type="'text'"
            v-model="expressOrder"
        />
      </div>

<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit" @click="editTranslate(['fastOrders_created'])"></span>-->
<!--        <DatePickerDefault-->
<!--            :label="$t('fastOrders_created.localization_value.value')"-->
<!--            v-model="date"-->
<!--        >-->
<!--          <template slot="body">-->
<!--            <date-picker-->
<!--                v-model="date"-->
<!--                ref="datePicker"-->
<!--                valueType="format"-->
<!--                range-->
<!--                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"-->
<!--                :format="'MM/DD/YY'"-->
<!--                :placeholder="'mm/dd/yy'"-->
<!--            ></date-picker>-->
<!--          </template>-->
<!--        </DatePickerDefault>-->
<!--      </div>-->


    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
// import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
// import DatePicker from 'vue2-datepicker'

export default {
  name: "ExpressFastOrdersFilter",
  components: {
    // DatePickerDefault,
    // DatePicker,
    FilterBlock,
    MainButton,
    DefaultInput,
  },

  props: {
    showFilter: Boolean,
    countFilterParams: Number,
    filterGetParams: Object,
  },

  mixins: [filterFunc, usersFunc],

  data(){
    return {
      id: this.filterGetParams.id ? this.filterGetParams.id : '',
      expressOrder: this.filterGetParams.expressOrder ? this.filterGetParams.expressOrder : '',

      date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',

      options: [],

      filterCounts: [
        'id',
        'expressOrder',
        'date',
      ],
    }
  },

  watch: {
    filterGetParams(newVal) {
      this.id = newVal.id ? newVal.id : ''
      this.expressOrder = newVal.expressOrder ? newVal.expressOrder : ''
      this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
    },

  },

  methods: {
    closeFilter() {
      this.$emit('close')
    },

    resetFilter() {
      let data = this.resetFilterParams(this, this.filterCounts)

      this.$emit('changeFilter', data)
    },

    changeFilter() {
      let data = this.changeFilterParams(this, this.filterCounts)
      data.date = this.changeDateParams(this, 'date')

      this.$emit('changeFilter', data)
    },
  },
}
</script>

<style scoped>

</style>
