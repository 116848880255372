<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          '',
        ])"></div>

      <div class="content-tabs content-tabs--separator">

      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-right-block">
        <router-link
            :to="$store.getters.GET_PATHS.reportsExpressFastOrdersCreate"
        >
          <MainButton class="btn-fit-content"
                      :value="$t('common_AddNew.localization_value.value')"
                      :ico="'ico'"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus"/>
            </template>
          </MainButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'


export default {
  name: "ExpressFastOrdersHead",
  components: {
    MainButton,
    ToggleFilterButton,
    PlusIconSVG,
  },

  props: {
    showFilter: Boolean,
    countFilterParams: Number,
  },

  created() {

  },

  data() {
    return {

    }
  },



  methods: {

    selectAll(val) {
      this.$emit('selectAll', val)
    },

  }
}
</script>

<style scoped>
@media (min-width: 680px) {
  .btn-left-block{
    flex-wrap: wrap;
    max-width: 50%;
  }

  .btn-left-block-i{
    margin-left: 0!important;
    margin-right: 15px;
  }
}

@media (max-width: 680px){
  .btn-right-block{
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .btn-right-block .btn-right-block-i{
    /*margin-top: 15px;*/
    margin-right: 0!important;
  }

  .btn-right-block .btn-right-block-i:last-child{
    margin-top: 0;
  }

  .btn-left-block{
    display: flex;
    flex-direction: column;
    max-width: 50%
  }

  .btn-left-block-i{
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .btn-left-block-i:last-child{
    margin-top: 0;
    margin-bottom: 0;
  }

  .btn-left-block-i--link{
    font-size: 12px;
  }

  .light-bg-block{
    align-items: flex-end;
    width: calc(100% + 30px);
    margin: 0 -15px;
  }
}
</style>

