<template>
  <div class="fragment">
    <template v-if="$store.getters.getExpressFastOrders.length > 0 && !$store.getters.getExpressFastOrdersLoading">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'fastOrders_id',
          // 'fastOrders_comment',
          'fastOrders_status',
          'common_user',
          'fastOrders_created',
          'common_manage',
          'fastOrders_getLabels',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th class="pr-3 pl-3">{{$t('fastOrders_id.localization_value.value')}}</th>
<!--            <th>{{$t('fastOrders_comment.localization_value.value')}}</th>-->
<!--            <th>{{$t('fastOrders_status.localization_value.value')}}</th>-->
<!--            <th>{{$t('common_user.localization_value.value')}}</th>-->
            <th width="100%">{{$t('fastOrders_created.localization_value.value')}}</th>
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr
              v-for="(item, index) in $store.getters.getExpressFastOrders"
              :key="index"
          >
            <td class="pr-3 pl-3">
              #{{item.id}}
            </td>
<!--            <td>-->
<!--              <div class="table-row">-->
<!--                <div class="d-flex align-center">-->
<!--                  <v-popover-->
<!--                      class="site-tooltip"-->
<!--                      offset="5"-->
<!--                      placement="top"-->
<!--                      trigger="hover"-->
<!--                  >-->
<!--                    <div class="ellipsis">{{ item.comment }}</div>-->
<!--                    <template slot="popover">-->
<!--                      <p>{{ item.comment }}</p>-->
<!--                    </template>-->
<!--                  </v-popover>-->

<!--                </div>-->
<!--              </div>-->
<!--            </td>-->
<!--            <td>-->
<!--              <template v-if="item.status === FAST_ORDER_STATUSES.FAST_ORDER_STATUS_NEW.value">-->
<!--                {{$t('fastOrders_newStatus.localization_value.value')}}-->
<!--              </template>-->
<!--              <template v-if="item.status === FAST_ORDER_STATUSES.FAST_ORDER_STATUS_IN_PROGRESS.value">-->
<!--                {{$t('fastOrders_inProgressStatus.localization_value.value')}}-->
<!--              </template>-->
<!--              <template v-if="item.status === FAST_ORDER_STATUSES.FAST_ORDER_STATUS_SUCCESS.value">-->
<!--                {{$t('fastOrders_successStatus.localization_value.value')}}-->
<!--              </template>-->
<!--              <template v-if="!item.progress_percentage && $store.getters.getExpressFastOrdersSocketItemId === item.id">-->
<!--                <span class="color-green-important">(0%)</span>-->
<!--              </template>-->
<!--              <template v-if="+item.progress_percentage >= 0 && +item.progress_percentage !== 100">-->
<!--                <span class="color-green-important">({{item.progress_percentage}}%)</span>-->
<!--              </template>-->
<!--            </td>-->
<!--            <td>-->
<!--              <LinkButton-->
<!--                  v-if="item.user"-->
<!--                  :value="item.user.email"-->
<!--                  :link="$store.getters.GET_PATHS.mainSettingsUsers + `?userId=${item.user_id}`"-->
<!--              />-->
<!--            </td>-->
            <td>
              {{ item.created_at | moment("DD MMM, YYYY HH:mm") }}
            </td>
            <td>
              <div class="table-row">
                <div class="table-right">
                  <LinkButton
                      class="mr-2"
                      :value="$t('common_edit.localization_value.value')"
                      :type="'edit'"
                      :link="$store.getters.GET_PATHS.reportsExpressFastOrdersEdit + '/' + item.id"
                  />
                  <LinkButton
                      class="mr-2"
                      :value="$t('common_delete.localization_value.value')"
                      :type="'delete'"
                      @click.native="$emit('deleteItem', item.id)"
                  />
                  <LinkButton
                      :value="$t('common_export.localization_value.value')"
                      :type="'dog'"
                      :target="'_blank'"
                      @click.native="exportExpressFastOrders(item.id)"
                  />


<!--                  <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton"-->
<!--                                 class="right"-->
<!--                  >-->
<!--                    <template slot="item">-->
<!--                      -->
<!--                    </template>-->
<!--                    <template slot="item">-->
<!--                     -->
<!--                    </template>-->
<!--                    <template slot="item">-->
<!--                     -->
<!--                    </template>-->
<!--                  </ManagerButton>-->
                </div>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getExpressFastOrders"
                 :key="index"
            >
              <ExpressFastOrdersTableMobile
                  :item="item"
                  :selectedNow="selectedNow"
                  @deleteItem="$emit('deleteItem', item.id)"
              />
            </div>

          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getExpressFastOrdersCommonList.next_page_url !== null && $store.getters.getExpressFastOrders.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextExpressFastOrdersPage}"
                :count="$store.getters.getExpressFastOrdersCommonList.total - $store.getters.getExpressFastOrdersForPage * $store.getters.getExpressFastOrdersCommonList.current_page < $store.getters.getExpressFastOrdersForPage ?
                  $store.getters.getExpressFastOrdersCommonList.total - $store.getters.getExpressFastOrdersForPage * $store.getters.getExpressFastOrdersCommonList.current_page:
                  $store.getters.getExpressFastOrdersForPage"
            />
<!--            <ExportBtn-->
<!--                @click.native="$emit('downloadFiles')"-->
<!--                class="table-bottom-btn__right"/>-->
          </div>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getExpressFastOrdersLoading === false && $store.getters.getExpressFastOrders.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>


  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import ExpressFastOrdersTableMobile from "./ExpressFastOrdersTableMobile/ExpressFastOrdersTableMobile";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  // import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import {FAST_ORDER_STATUSES, FAST_ORDER_STATUS_SUCCESS} from "../../../../../../../staticData/staticVariables";
  import {expressFastOrdersMixin} from "@/mixins/expressFastOrdersMixins/expressFastOrdersMixin";


  export default {
    name: "NewPaymentsTable",

    components: {
      LinkButton,
      // ManagerButton,
      NoResult,
      ExpressFastOrdersTableMobile,
      ShowMore,
    },

    mixins: [mixinDetictingMobile, expressFastOrdersMixin],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },


    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,
        hideEditField: false,

        show1: false,
        show2: false,

        isModalInfoPopup: false,
        infoPopupItemId: 0,

        FAST_ORDER_STATUS_SUCCESS: FAST_ORDER_STATUS_SUCCESS,
        FAST_ORDER_STATUSES: FAST_ORDER_STATUSES,
      }
    },

    methods: {

    }

  }

</script>

<style scoped lang="scss">
  .comment-row{
    overflow: hidden;
    text-overflow: ellipsis;
    width: 356px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
  }

  .table-manage-list {
    width: 240px;


    .manager-btn {
      max-width: 370px;
      width: 100%;
    }
  }


  @media (max-width: 1900px){
    .comment-row{
      width: 250px;
    }
  }

  @media (max-width: 1400px){
    .comment-row{
      width: 200px;
    }
  }

  .site-table thead th:first-child,
  .site-table tbody td:first-child{
    padding-right: 0;
  }

  .site-table thead th:nth-child(2),
  .site-table tbody td:nth-child(2){
    padding-left: 0;
  }

  .site-table thead th:last-child,
  .site-table tbody td:last-child{
    padding-right: 5px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .small-table th,
  .small-table td{
    white-space: nowrap;
  }

  .conso-user-select {
    min-width: 150px;
  }

  .site-table .ellipsis {
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: inline-block;
  }

</style>
