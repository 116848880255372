<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fastOrders_id'])"></span>

        <DefaultInput
            :label="$t('fastOrders_id.localization_value.value')"
            :type="'text'"
            v-model="id"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fastOrders_status'])"></span>
        <DefaultSelect
            class="w-100"
            :options="FAST_ORDER_STATUSES_ARRAY"
            :label="$t('fastOrders_status.localization_value.value')"
            :otherValue="'translation'"
            :selected="_.find(FAST_ORDER_STATUSES_ARRAY, {value: status})"
            @change="setStatus"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fastOrders_created'])"></span>
        <DatePickerDefault
            :label="$t('fastOrders_created.localization_value.value')"
            v-model="date"
        >
          <template slot="body">
            <date-picker
                v-model="date"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>


    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
import DatePicker from 'vue2-datepicker'
import {FAST_ORDER_STATUSES_ARRAY} from "../../../../../../../staticData/staticVariables";
import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

export default {
  name: "FastOrdersFilter",
  components: {
    DefaultSelect,
    DatePickerDefault,
    DatePicker,
    FilterBlock,
    MainButton,
    DefaultInput,
  },

  props: {
    showFilter: Boolean,
    countFilterParams: Number,
    filterGetParams: Object,
  },

  mixins: [filterFunc, usersFunc],

  data(){
    return {
      id: this.filterGetParams.id ? this.filterGetParams.id : '',
      status: this.filterGetParams.status ? this.filterGetParams.status : '',

      // userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
      // userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

      date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',

      options: [],
      FAST_ORDER_STATUSES_ARRAY: FAST_ORDER_STATUSES_ARRAY,

      filterCounts: [
        'id',
        'status',
        'date',
        // 'userName',
        // 'userId',
      ],
    }
  },

  watch: {
    filterGetParams(newVal) {
      this.id = newVal.id ? newVal.id : ''
      this.status = newVal.status ? newVal.status : ''
      this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''

      this.userId = newVal.userId ? newVal.userId : ''
      this.userName = newVal.userName ? newVal.userName : ''
    },

  },

  methods: {
    closeFilter() {
      this.$emit('close')
    },

    // functionSearch(value, loading) {
    //   loading(true)
    //   this.getFilteredUsers(value, this, 'options', loading)
    // },

    // changeUsersFilter(value) {
    //   this.userId = value.id
    //   this.userName = value.contacts[0].user_full_name
    // },

    resetFilter() {
      let data = this.resetFilterParams(this, this.filterCounts)

      this.$emit('changeFilter', data)
    },

    changeFilter() {
      let data = this.changeFilterParams(this, this.filterCounts)
      data.date = this.changeDateParams(this, 'date')

      this.$emit('changeFilter', data)
    },

    setStatus(val) {
      this.status = val.value
    },
  },
}
</script>

<style scoped>

</style>
