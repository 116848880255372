<template>
  <ExpressFastOrdersTableAdmin
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      @changeTab="changeTab"
      @resetFilter="resetFilter"
      @changeFilter="changeFilter"
      @showMore="showMore"
      @reload="reload"
      @deleteItem="deleteItem"
  />
</template>

<script>
  import ExpressFastOrdersTableAdmin from "./ExpressFastOrdersTableAdmin/ExpressFastOrdersTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {fastOrdersSocketMixin} from "../../../../../mixins/fastOrdersMixins/fastOrdersMixin";

  export default {
    name: "ExpressFastOrdersTable",

    components: {
      ExpressFastOrdersTableAdmin,
    },

    mixins: [routeFilter, fastOrdersSocketMixin],

    computed: {
      router: function() {
        return this.$route.query
      }
    },

    data() {
      return {
        forPage: this.$store.getters.getExpressFastOrdersForPage,
        page: 1,
        onePage: 1,

        filterId: '',
        filterExpressOrder: '',
        filterDate: [],

        filterUserId: '',
        filterUserName: '',

        countFilterParams: 0,
        filterGetParams: {},

      }
    },

    mounted() {
      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,

          id: this.$route.query.id,
          expressOrder: this.$route.query.expressOrder,
          date: this.$route.query.date,

          userId: this.$route.query.userId,
          userName: this.$route.query.userName,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        this.$store.commit('setExpressFastOrdersFilter', window.location.search)

        if (next) this.$store.commit('setNextExpressFastOrdersPage', true)
        this.$store.dispatch('fetchExpressFastOrders', url).then(() => {
          this.$store.commit('setNextExpressFastOrdersPage', false)
        })


        this.checkCountFilter(['userName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
            myQuery,
            {
              filterId: 'id',
              filterExpressOrder: 'express_order_id',
            },
        )

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
              'byCreatedAt',
              date
          )
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      deleteItem(id) {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_yes',
          no: 'common_no'
        }

        let confirm = () => {

          this.$store.dispatch('deleteExpressFastOrders', id).then((response) => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.openNotify('success', 'common_notificationRecordChanged')
                this.$emit('close')
                this.filter()
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

    }
  }
</script>

<style scoped>

</style>